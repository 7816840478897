@import "@/styles/base/_colors";

.productPromoBanner {
  :global {
    .plp-promo-banner {
      width: 100%;
      background-color: $color-slate;
      &__layout {
        height: 100%;
        position: relative;
        &[role="link"] {
          cursor: pointer;
        }
        &.--col-1 {
          .plp-promo-banner {
            &__image {
              aspect-ratio: 1/1;
            }
          }
        }
        &.--col-1,
        &.--col-2,
        &.--col-3 {
          .plp-promo-banner {
            &__info {
              position: absolute;
              &-dead-center {
                top: 50%;
                transform: translate(-50%, -50%);
              }
              &-top-center {
                top: 0;
                transform: translate(-50%);
                padding-top: 75px;
              }
              &-top-center,
              &-dead-center {
                text-align: center;
                left: 50%;
                .plp-promo-banner__button {
                  text-align: center;
                }
              }
              &-top-left {
                top: 0;
              }
              &-bottom-left {
                bottom: 0;
              }
            }
            &__image {
              img {
                width: 100%;
              }
            }
            &__desc {
              * {
                color: inherit;
              }
            }
          }
        }
        &.--col-3 {
          .plp-promo-banner {
            &__desc,
            &__desc * {
              font-family: "Helvetica Now Text W05 Light";
              line-height: 44px;
              font-size: 36px;
              letter-spacing: -1.7px;
            }
          }
        }
      }
      &__image {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__info {
        display: block;
        &-bg {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      &__desc,
      &__desc * {
        font-family: "Helvetica Now Text W05 Light";
        line-height: 40px;
        font-size: 36px;
        letter-spacing: -0.1pc;
      }

      &__cta {
        &.kf-react-button.ghost {
          color: $color-white;
          border-color: $color-white;
          background-color: transparent;
          line-height: 24px;
          padding: 8px 30px;
          &:hover {
            color: $color-charcoal;
            background-color: transparent;
          }
        }
      }

      @media screen and (max-width: 991px) {
        overflow: hidden;
        &.--fixed-height {
          width: 100%;
        }
        &__layout {
          &.--col-1,
          &.--col-2,
          &.--col-3 {
            .plp-promo-banner__image {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
