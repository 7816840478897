@import "@/styles/base/_colors";
@import "@/styles/mixins/breakpoint";
@import "@/styles/base/_fonts";
.ListConfirmationModalWrapper {
  :global {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 111000001;
    display: flex;
    justify-content: center;
    align-items: center;
    .list-confirmation-modal {
      width: 339px;
      position: relative;
      padding: 50px 22px 40px 22px;
      background-color: $color-white;
      @media screen and (min-width: 821px) {
        width: 500px;
        padding: 70px 40px 50px 40px;
      }
      &.deleteFromList {
        padding: 25px 30px 40px 30px;
        @include breakpoint(tab-max) {
          padding: 50px 38px 40px 38px;
        }
        @include breakpoint(mobile-small) {
          padding: 50px 22px 40px 22px;
        }
        .list-ocnfirmation-content {
          .cta-button {
            margin-top: 40px;
            @include breakpoint(tab-max) {
              margin-top: 30px;
            }
          }
        }
      }
      @media screen and (min-width: 812px) {
        width: 500px;
        padding: 70px 40px 50px 40px;
      }
      .list-ocnfirmation-content {
        .header {
          color: $color-slate;
          font-family: $font-regular;
          font-size: 36px;
          font-style: normal;
          line-height: 44px;
          letter-spacing: -1.7px;
        }
        .discription {
          color: $color-dark-gray;
          font-family: $font-regular;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
          letter-spacing: -0.4px;
          margin-top: 15px;
        }
        .cta-button {
          width: 100%;
          display: flex;
          padding: 17px 60px;
          justify-content: center;
          align-items: center;
          margin-top: 40px;
          gap: 10px;
          background-color: $color-charcoal;
          &:disabled {
            background-color: $color-light-gray !important;
            border: 0px;
          }
          @include breakpoint(tab-max) {
            margin-top: 30px;
          }
          cursor: pointer;
          .cta-btn-txt {
            color: $color-white;
            text-align: center;
            font-family: $font-regular;
            font-size: 18px;
            font-style: normal;
            line-height: 26px;
            letter-spacing: -0.35px;
          }
        }
      }
      .close-btn {
        position: absolute;
        top: 25px;
        right: 40.1px;
        width: 17px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}
