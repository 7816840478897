@import "@/styles/base/_colors";
@import "@/styles/mixins/breakpoint";
@import "@/styles/base/_fonts";

.SaveToListWrapper {
  :global {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11100000;

    .save-to-list-modal {
      z-index: 11100009;
      position: fixed;
      width: 436px;
      height: 100vh;
      top: 0;
      right: 0;
      background-color: $color-white;
      padding-top: 25px;
      display: flex;
      flex-direction: column;

      @include breakpoint(tab-max) {
        width: 100%;
        margin: 0 auto;
        padding-top: 50px;
      }
    }

    .loading-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-items: center;
      justify-content: center;

      .loading-text {
        font-family: $font-regular;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.55px;
        text-align: left;
        color: $color-dark-gray;
      }
    }

    .modal-body {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      max-height: 72%;
      border: 0px;
      padding: 0px 20px 0px 20px;

      .back-close-btn {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .close-btn {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }

      .modal-content {
        display: flex;
        flex-direction: column;
        border: 0;
        max-height: 94%;
        flex: 0 1 auto;

        .list-header {
          font-family: $font-regular;
          font-size: 26px;
          font-weight: 325;
          line-height: 34px;
          letter-spacing: -1.5px;
          text-align: left;
          color: $slate;
          padding-top: 15px;
        }

        .list-dec {
          font-family: $font-regular;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.55px;
          text-align: left;
          color: $color-dark-gray;
          margin-top: 20px;
          @media screen and (min-width: 1024px) {
            margin-top: 30px;
          }
        }

        .create-new-list {
          font-family: $font-regular;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.4px;
          text-align: left;
          color: $slate;
          border-bottom: 1px solid $slate;
          width: fit-content;
          block-size: fit-content;
          margin-top: 30px;
          cursor: pointer;
        }

        .list-name-header {
          font-family: $font-regular;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.4px;
          text-align: left;
          color: $color-slate;
        }

        .modal-from-content {
          border: 0;
          width: 100%;
          overflow-y: auto;
          display: flex;
          flex: 0 1 auto;
          flex-direction: column;
          position: relative;
          margin-top: 30px;
          .list-names {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .list-radio {
              display: flex;
              flex-direction: row;
              position: relative;
              cursor: pointer;
              font-size: 12px;
              user-select: none;
              margin-right: 5px;
            }

            .list-radio input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }

            .radio-label {
              font-family: $font-regular;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.4px;
              text-align: left;
              margin-left: 30px;
              margin-top: -3px;
              color: $color-dark-gray;
            }

            .radio-label::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 1px solid $color-dark-gray;
              background-color: #fff;
            }

            .list-radio input:checked ~ .radio-label::before {
              width: 14px;
              height: 14px;
              top: 3.35px;
              left: 2.95px;
              background-color: $color-charcoal;
              border-color: $color-charcoal;
            }

            .list-radio input:checked ~ .radio-label::after {
              content: "";
              position: absolute;
              left: 0px;
              top: 0px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 1px solid $color-dark-gray;
            }
          }
          .textarea__wrapper {
            position: relative;
            padding: 2px 2px 26px 15px !important;
            border: 1px solid $color-dark-gray;
            border-radius: 4px;
            min-height: 125px !important;
            overflow: hidden !important;
            textarea {
              width: 100%;
              height: 100%;
              resize: none !important;
              padding: 26px 15px 0 0 !important;
              border-radius: 4px;
              border: none !important;
              background-color: $color-white;
              font-family: $font-regular;
              font-size: 18px;
              line-height: 1.44;
              letter-spacing: -0.7px;
              color: $color-slate;
              &:focus {
                border: 1px solid $color-dark-gray;
              }
              &::placeholder {
                opacity: 0;
              }
              &:focus {
                border: 1px solid $color-dark-gray;
              }
              &:not(:placeholder-shown) + label {
                transform: translate(0, 0) scale(1);
                background: $color-white;
                min-width: -webkit-fill-available !important;
              }
              &:placeholder-shown + label {
                cursor: text;
                max-width: 66.66%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transform-origin: left bottom;
                transform: translate(0, 88%) scale(1.5);
              }
              &:focus + label {
                transform: translate(0, 0) scale(1);
                background: $color-white;
                min-width: -webkit-fill-available !important;
              }
            }

            label {
              position: absolute;
              left: 15px !important;
              font-family: $font-regular;
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: -0.35px;
              color: $color-dark-gray;
              margin-bottom: 0;
              transition: all 0.2s;
              min-width: auto;
              background: $color-white;
            }
            .contact-us-count {
              position: absolute;
              bottom: 6.5px !important;
              right: 10px;
              color: $color-dark-gray;
              font-family: $font-regular;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.3px;
            }
          }
        }
      }
    }

    .action-btn {
      width: 100%;
      padding: 20px 40px 30px 40px;
      margin-top: auto;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

      @include breakpoint(tab-max) {
        height: 100px;
      }

      @media screen and (min-width: 1025px) {
        height: 110px;
      }

      .action-btn-item {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $charcoal;
        color: $color-white;
        border: 0;

        @include breakpoint(tab-max) {
          height: 50px;
        }
        &:hover {
          background-color: $charcoal !important;
          color: $color-white;
        }
        &:disabled {
          background-color: $color-light-gray !important;
          border-color: $color-light-gray;
        }
        &:hover {
          background-color: $color-light-gray;
          border-color: $color-light-gray;
        }
      }

      .action-btn-item-txt {
        font-family: $font-regular;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.35px;
        text-align: center;
        color: $color-white;
      }
    }
  }
}
